import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import 'antd/dist/antd.css';
import MenderClubContract from "../../contracts/MenderClub.json";
import getWeb3 from "../../getWeb3";
import connectWeb3 from "../../connectWeb3";
import axios from 'axios';
import MenderShow1 from '../../assets/mender-show.png'
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import { SERVER_URL } from "../../utils/constant";
export default class VideoDetails extends Component {
    state = { 
        web3: null, 
        accounts: null, 
        contract: null ,
        nftList:[],
        list:[],
        showModel:false,
        info:null
      };
      componentDidMount = async () => {
        try {
          // Get network provider and web3 instance.
          const web3 = await getWeb3();
    
          // Use web3 to get the user's accounts.
          const accounts = await web3.eth.getAccounts();
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          const deployedNetwork = MenderClubContract.networks[networkId];
          const instance = new web3.eth.Contract(
            MenderClubContract.abi,
            deployedNetwork && deployedNetwork.address,
          );
          // Set web3, accounts, and contract to the state, and then proceed with an
          // example of interacting with the contract's methods.
          this.setState({ web3, accounts, contract: instance },this.getAllSaleNft);
        } catch (error) {
          // Catch any errors for any of the above operations.
          console.log(error);
        }
        let that=this;
        await axios.get(SERVER_URL+'/getVideoList')
           .then(function (response) {
            
            that.setState({
              list:response.data.data.list,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      };
      runExample = async () => {
        const { accounts, contract } = this.state;
        // Stores a given value, 5 by default.
        await contract.methods.set(5).send({ from: accounts[0] });
    
        // Get the value from the contract to prove it worked.
        const response = await contract.methods.get().call();
    
        // Update state with the result.
        this.setState({ storageValue: response });
      };
      
    render(){
            const list = this.state.list.map((item)=>
                <div className="team-silder-item">
                  <div className="team-silder-item-main">
                    <div className="team-silder-img"><img src={MenderShow1} /></div>
                    <div className="team-silder-name f-big">{item.name}</div>
                    <div className="team-silder-desc">Product introduction Product introduction</div>
                    <div className="video-play juzhong"></div>
                  </div>
                </div>
            );
            return (
              <div>
                <div className="content-wrapper">
                  <Header />
                  <div className="video-wrapper">
                    <div className="team-silder clearfix">
                      {list}
                    </div>
                  </div>
                  
                  <Bottom />
                </div>
              </div>
            )
    }
}