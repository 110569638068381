import React, { Component } from "react";
import 'antd/dist/antd.css';
import http from "../../utils/httpRequest"
import Header from "../../components/Header";
import { Calendar,message} from "antd";

export default class Incident extends Component {  
  state = {
    list: [],
    filterdate: "",
    newsTag:[],
    loading: false,
    pageNum: 1,
    pageSize: 10,
    sort:null,    //热门标签
    hasMore:true
  };
  componentDidMount = async () => {
    window.addEventListener('scroll', this.handleScroll, false);
    this.getNewsTag();
    this.updateData(this.state.sort,false);

  };
  componentWillUnmount = async()=>{
    window.removeEventListener('scroll', this.handleScroll)
  };
  //获取滚动条在Y轴的距离
  getScrollTop = () => {
    var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
    if(document.body){
      bodyScrollTop = document.body.scrollTop;
    }
    if(document.documentElement){
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  };
  //获取内容的总高度
  getScrollHeight = () => {
    var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
    if(document.body){
      bodyScrollHeight = document.body.scrollHeight;
    }
    if(document.documentElement){
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
    return scrollHeight;
  };
  //获取浏览器视窗高度
  getWindowHeight = () => {
    var windowHeight = 0;
      if(document.compatMode == "CSS1Compat"){
        windowHeight = document.documentElement.clientHeight;
      }else{
        windowHeight = document.body.clientHeight;
      }
    return windowHeight;
  };
  handleScroll = () => {
    if(this.getScrollHeight() - this.getScrollTop() - this.getWindowHeight() < 10){
      // 解除绑定
      window.removeEventListener('scroll', this.handleScroll ,false);
      // 在这里发送请求
      console.log('到底了')
      this.updateData(this.state.sort,false)
      // 并在请求到数据后重新开启监听
    setTimeout(()=>window.addEventListener('scroll', this.handleScroll, false), 500)
  　}
  }
  calDayUnit=(timeString)=>{
    
    let timeDate=new Date(timeString); 
    let month=timeDate.getMonth()+1;
    let day=timeDate.getDate();
    return month+"-"+day;
  };

  getHour=(timeString)=>{
    let timeDate=new Date(timeString); 
    let hour=timeDate.getHours();
    let min=timeDate.getMinutes();
    return hour+":"+min;
  };
  getNewsTag = async () => {
    
    let _this = this;
    http("get",'/api/index/getNewsTag').then(res => {
    if(res.code===200){
      if(res.list.length>0){
        _this.setState({
          newsTag:res.list
        });
        _this.forceUpdate()
      }
    }

  });
  };
  changeTag =  (item)=>{
    console.log("sort",this.state.sort);
    console.log("changesort",item.dictValue);
    if(item.dictValue!=this.state.sort){
      console.log(item);
      this.updateData(item.dictValue,true);
    }
  };
  updateData = async (sort,first) => {
    if(this.state.loading==true){
      return false;
    }
    var pageNum=1;
    var hasMore=true;
    if(first){
      this.setState({
        sort:sort,
        list:[],
        pageNum:1,
        hasMore:true
      })
    }else{
      pageNum=this.state.pageNum;
      hasMore=this.state.hasMore;
    }
    let _this = this;
    if(hasMore==false){
      return false;
    }
    this.setState({ loading: true });
    http("get",'/api/user/getIncident',{
      "pageNum":pageNum,
      "pageSize":this.state.pageSize,
      "sort":sort,
  }).then(res => {
    if(res.code===200){
      if(res.list.length>0){
        let reslist=res.list;
        let comlist=_this.state.list;
        for(let index=0;index<reslist.length;index++){
          let dayunit=this.calDayUnit(reslist[index].createTime);
          
          if(comlist.hasOwnProperty(dayunit)){
            comlist[dayunit].push(reslist[index]);
          }else{
            comlist[dayunit]=[];
            comlist[dayunit].push(reslist[index]);
          }
        }
        _this.setState({
          list:comlist,
          pageNum:_this.state.pageNum+1
        });
        _this.forceUpdate()
      }else{
        _this.setState({
          hasMore:false
        })
      }
    }else{
      
      _this.setState({
        hasMore:false
      })

      
    }

  })
  .catch(err=>{
    _this.setState({
      hasMore:false
    })
  }).finally(()=>{
    this.setState({loading:false});
  });
  };


  render() {
    var listkeys=Object.keys(this.state.list);
    return (<div className="coin-incident">

      <div className="content-wrapper">
      { <Header /> }
      </div>
      <div className="zl-title" >
          <div className="zl-title-inner" >
            <h2 >MenderNews</h2>
            <p >花最少的时间了解行业机会，MenderNFT持有者私享</p> 
          </div>
      </div>
      
      <div className="incident-content">
      
        <div className="container-warp" style={{display: "flex",justifyContent: "space-between",paddingTop: "24px",width:"1200px",backgroundColor: '#ffffff',position:"relative"}}>
        
          <div className="main" >
            <div className="kx-list" >
              {
            
                  listkeys.map((item,index)=>{
                    return (
                      <div   className="outer">
                        <div  id={item} className="kx-date">
                          <div  className="month">{item.split("-")[0]}月</div>
                          <div  className="date">{item.split("-")[1]}</div>
                          <div  className="week">
                          </div>
                        </div>
                        {
                          this.state.list[item].map((iteminner,indexinner)=>{
                            return (
                              <div  className="item">
                                <div  className="point">
                                </div> <div  className="pubtime">{this.getHour(iteminner.createTime)}</div>
                                <div  className="content">
                                  <div><img style={{maxWidth:"100%"}}  src={"http://www.menderclub.co/prod-api"+iteminner.url} /></div>
                                  <div className="n-title">事件解析：{iteminner.miaoshu}</div>
                                  <div className="n-builder" >【社区建设者】：{iteminner.name}</div>
                                </div>
                              </div>
                            )
                          })
                        }
                        
                        
                      </div>
                    )
                  })
                
                
              }




          
            </div>
          </div>

          <div class="sidebar">
            <div class="newest-floor for-pc"><div>
              <h3 class="side-title">热门标签</h3> 
              <div class="article-list">
                {
                  this.state.newsTag.map((item)=>{
                    return (
                      <div onClick={this.changeTag.bind(this,item)} class="article-item">{item.dictLabel}</div>
                    )
                  })
                }
                </div> 
              </div>
            </div>  
          </div>
        </div>
      </div>

    </div>)
  }
}