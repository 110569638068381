import React, { Component } from "react";
import 'antd/dist/antd.css';
import { Modal} from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenderShow1 from '../../assets/mender-show.png'
import tuiteImg from '../../assets/header-icon1.png'
export default class MenderShow extends Component {
  state={
    isModalVisible:false,
    playUrl: null,
  };
  tolink=(e)=>{
    console.log(e);
    if( (e.content!=null) && (e.content!="")){
      window.open(e.content,'_target')
    }
  };
  openVideo=(e)=>{
    this.setState({
      playUrl:""+e.url
    });
    this.showModal();
    
  };
  showModal = () => {
    this.setState({
      isModalVisible:true,
    });
  };

  hideModel = () => {
    this.setState({
      isModalVisible:false
    });
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      centerPadding:'150px',
      speed: 1000,
      autoplaySpeed: 5000,
      cssEase: "linear"
    };
    if(this.props.list.length>0){
      const listItems = this.props.list.map((item,index) =>
      <div className="team-silder-item" key={index}>                 
        <div className="team-silder-item-main">
          <div className="team-silder-img">
            <video width="100%" height="100%" controls>
            <source  src={"http://www.menderclub.co/prod-api"+item.url} type="video/mp4" preload="none" poster={"http://www.menderclub.co/prod-api"+item.fengmian} />
            您的浏览器不支持Video标签。
          </video>
            {/* <div className="video-play juzhong"></div> */}
          </div>
          <div className="team-silder-name f-middle">{item.name}</div>
        </div>
      </div>

        
      );
      return (
        <div className="team-silder">
        <Slider {...settings}>
        
        {listItems}
          
        </Slider>

        <Modal title="MenderShow" visible={this.state.isModalVisible} onOk={this.hideModel.bind(this)} onCancel={this.hideModel.bind(this)}>
          <video width="100%" height="100%" controls>
            <source  src={this.state.playUrl} type="video/mp4" />
            您的浏览器不支持Video标签。
          </video>
      </Modal>
        </div>
      );
    }else{
      return (
        <div className="team-silder">
        
        </div>
      );
    }
  }
}