import React, {Component} from "react";
import 'antd/dist/antd.css';
export default class Proof extends Component {
    state = { 
        
       
      };
      componentDidMount = async () => {
        
        let that=this;
         this.updateMintData();
      };
      updateMintData=async ()=>{
       
        let wt=[
          "0xa94a5b1cb6039C4ae655430d1Cb24457c4f4C574",
        "0x88F659b4B6D5614B991c6404b34f821e10390eC0",
        "0x8cf1c4e3098802208872c54273b38aE0e6856166",
        "0x2342CF4a56B94741076AE822415357fe378738fe",
        "0xe5B7AcfA1eB886F1f13A3b87FC2F818C6b15eAFc",
        "0x728eA369966Ef5da893162669B80E53bc5aCbf2e",
        "0x35D6392f41093f5D6F88fE1a3326a5D8D73B23D2",
        "0x6626Bd3A0a0604724F3e4afb6a5FF89dda30c758",
        "0xCeA3cD34dc8d4c38a5f1830b1AD7E4975A814426",
        "0xf2f3a8219E64b24EaeE34E2da2228228Ca239b5E",
        "0xFF05EFBE0FC6B218b340D489B2fd631539Da03d1",
        "0xF73429182E65A3c53cAe4CD9Ae1B1fA640647EDc",
        "0x09e93B57f3C1300f5A7b5859B042E34BB1c96C4F",
        "0x6863c8C99b01F384F611Bde87db04E5ca0b24398",
        "0x96Dc7c7056cA6B7aC33a04b294cd74af001134Fe",
        "0x674cB6Cb34556f4E3aB0c0C88f68E596B7B3eB72",
        "0x705C8653674740Bb369f06bb3784ed56838d8862",
        "0xfB291E47d404AE10788a7286867b0Be2192E9efE",
        "0xa3B21f3e4BbdEE44eE9331600aA72cbC0bE6B88A",
        "0xaEdce5dcDd95D25B6885e4d57Db144a4878ee381",
        "0xC1A8f6175Cc7e32Df19215fB09262579C8ba88D1",
        "0xfFB18dB2DF08a05Eb8199f3936491505a03Be0aD",
        "0x543AE6B91a38B37BC1BDd3C6911b4723A3CE5E97",
        "0x63BEe620EBfd173d74a4bE25Eb330C52Ea3cb6d4",
        "0xaA4007fbd7b9e5b6445769ca6bC13E86D850B2FB",
        "0xBabe7b7D0A9A4DD3Dd3CbF9cfE08Ae6c596267f1",
        "0xB1b8794bCec0d52033bEC4911115CA6711d34804",
        "0x5aA5Abc3fB258f64B83ed8C52a03BA8Da67B3259",
        "0x9a5465d208e25076F747a4a7E9120a17524749Ef",
        "0x7327e896565601722cBA4BDb28eD9c061eeE3306",
        "0x6240b674e102ba22501d8141A827399e3609Ba69",
        "0x32ccd327BbB192c69d730B0d2aA0F2Cf6aC38f5a",
        "0xe54D9f6A5D86e00BbC40B069A1EB01415Ff60766",
        "0x85bed7D5c8838a9EaEB99521Dfef59F891E857d6",
        "0x41f65835417BE3566db537c27024934b54CfF098",
        "0xdE307dd55191bc640d16DA5412CEd06c13b5B192",
        "0x01c34FCab1070fAeDF012889f217Db60eb64c96f",
        "0xf827860c1b7cBbb8fB7397f7D07F36e81DF57e1D",
        "0x4709F504bc2d3e73d964ea1019484b81750F3058",
        "0x91CB05066EFB640A143714CF2595b4E6800401bf",
        "0xeAf9170309D0e75209d5dd3dff5C0c70512d72ab",
        "0x46bAb4E1523262B876809F106a68066F861851a4",
        "0xb7354240718778Ff6F2D89d78ABBCB41256CED50",
        "0x15d0C00Ead02AF3a11bF7E5678D1DB683313c83A",
        "0xaB9e3528ade8A73fb1F705d5D0E4a150F8127b21",
        "0x6b9B4774aFdF2239B99521c84386A4061798EaF1",
        "0x4F069C9E8dED7bD5d229FE7b52BefC55f1869dA3",
        "0x5c4bc24dA2fd5151D76B46077D12E3A3883279bE",
        "0x578A8DFB711adC80C4655098Cef563184aF4bEFE",
        "0x44C6FD17e02b41CEF5CB96A53cB92b652553699b",
        "0xe16FCd96642e72369a55174069496eCebdD7C58f",
        "0xB849Cc92589DF99BF538832a8Fa1eFF5022aa419",
        "0xf93ef56408581007fcCC069A1D5019b2208295cB",
        "0xfc13c7E40EFcFC96390DA48d9497735279F75C77",
        "0x2b0ED890b1a42B75ECB0256030FEE6a536eEFdE3",
        "0xB0D0dD20dD3Ed1D80175edb37eA80b6Db24E291c",
        "0x22561fCfb8b4a6d488DD2A434Dc567B82b997713",
        "0xBF33c153eac437879937a7eFd027f2A7948d61B5",
        "0xBC342b01598FC6DBd45ad27052DB9cF688D478Fb",
        "0xe30f870dC3D038b8618A16997ac1f71f85C4cac3",
        "0x6D6aC43874725baA19BCce53b3F109b825A7Fb9E",
        "0x1E0Ea9082604B3BA60039B05D556B06c1ed34cfC",
        "0x942e815F167D165EC8FE33B868e7d07B4520EA4a",
        "0xDfb36eDc333e926cE856cA14C71333876cf9161E",
        "0xbD7E48E771A2EEcc7a868Fc243489c6E11Ab0D4B",
        "0x2Fe075e750CE098C1CE417719067d1ee19944537",
        "0x05884338E94ef77cF96A42B911f7E3121f2ab70e",
        "0x01Eb937BfeCb17321344E0FCf4CCF4c91ff6C79F",
        "0xE9439f4EE3FAE7E15fdC9C3B3FB84833Cc721D24",
        "0x2Cd63A1a8Bff8a8220C6349ab95Ac4D115D2e9A0",
        "0x9d01A6D2a3331C3B181058273348513081C96010",
        "0xB11065975b500a42465A2b601db53CD027A6B9ba",
        "0x39323b51B7321695027a49607fd8B50D56bA3C6d",
        "0x9Fb7563A1969f034e8FeB83e499C967D31538E86",
        "0x3Ffea8Cf3cEA0Cdc60F514960459a214E8D2181f",
        "0xAfB1BB079496deFDDFA1da3618971Ea9Dc2DC4c2",
        "0xED4e45a84297B43efFa7554Fd60026648FD03F19",
        "0x1A2b157E8fdfd382bAc63A81E8894102c73249D8",
        "0x2049DE4DE5E206a991CA8b1fA5C5D5115F9e22C5",
        "0x53921Da1fA2b4A41Bd134b32844db3F1A92f2Dd3",
        "0xDD0Da944b1CB8b87f37D318df29Cab10f4088221",
        "0x54BbF603af39b028dA873CaA1218A220E07D65E6",
        "0xf4bbf6c5f19e144a0f09f5bdb8192746f203d750",
        "0x8bD2f1B9c4491F6664c6b5eE9FeB3E625cA86cFd",
        "0x4EF852099c33c927af537b9370125F2c5265c130",
        "0x770010335928b563105B5C3D158A38Ed96483222",
        "0x20a785B21A507650A1a303779DfF130C3610E879",
        "0xA1E2CaFAC77847f0510ef2F030d931934cEcA10c",
        "0xdaee2A62E9a1a13721BACB3F04d3f80b6a0719A5",
        "0x5013c2e1d5e5f7543203d747343a920f6be6d0bc",
        "0xF77E0243f31848f1180059e220c4edFa3954446e",
        "0xAF48A928Fb07327ac7Dcdaf052DA0669CDeCB213",
        "0xEF19315FD3212257E3de7376F6270FB785C75a04",
        "0xd56b05b7961D17575EC58EF9cb317261411Bc0D8",
        "0xb8Ca6F7cC7237a0DABD3160BB02617491221Aa01",
        "0x370176962ceFD81342b2eD6BF4E1F604C4c069b4",
        "0x930a8367C34E2a39654b73952AE523859AdedB4b",
        "0x0633ee736B51b2278d79bbF2667ac913855571a9",
        "0x7d4875bd28C8FC049B3CD5fB1c62dbF3E954A8bE",
        "0x6D2d2aDe34aa0C7791d5D82BEFa407E140074079",
        "0x374EE3B1C60752C3e2D251bacD4a7076548Dd4B4",
        "0x6EF63E6caFa41C93a67f0579D87BC526186e526b",
        "0xD3e22e66fa2b8D4D338d6762bc107Bea7FB5EEFB",
        "0x87185b97Ebe8cA1054126f08DBccE094600B0408",
        "0x9D772ed3474808CD352ac8Ea1a08cfbC27003Df4",
        "0x51Fdf97F3225000B7c12638a5065a5BEa8e89435",
        "0xf1B70eB3480B69034DaB1704922FcD887d64Bad7",
        "0x05cF04eDAc6Cdc4404E8B3D1aF0EF1A7092636B0",
        "0x0ffd20F614Ae8f86c1E5409e2d004c2EC696a42b",
        "0x8B788e8326756C5A0A5FFc22B8d2a7C993894768",
        "0xfCF63A766160aA28fC6C76b4102b8591d2896dA5",
        "0xc9430DEFd2F4b1b0476f628F53DFFAe67b9b853C",
        "0x2325288D7d4F8c1dE56F625721fDb133D9048A55",
        "0x30189EF02E5cEa699534d30B423c2d4467CB5740",
        "0x4f185E0E2F130167Cd842c73532672Ecfd7FC6B4",
        "0xf1e64BdeE88e3f51F3cC64546CF54DFEe8215383",
        "0x66745cb682C50cFd4bb79Bd374Df5D94B066BC94",
        "0x2956A182c4DE1b7F930b7AE265e9B551DB99876D",
        "0x0E4cD750Be597743De96bdDb6EC6d79E2E998B27",
        "0x228F3a73Cad75a2483c98372A9d73E05e5598244",
        "0x9d01A6D2a3331C3B181058273348513081C96010",
        "0x30189EF02E5cEa699534d30B423c2d4467CB5740",
        "0xAd004Bce6c270112e4588146cB05Edd98FbD20e6",
        "0x5230A075F672623AA8bE469318bDF1f0669Bbe12",
        "0x57046a399c591C0398DD41aFce4E6b82E1Aecaf4",
        "0x883063bbE5370F66154DE8149153e9ABc362e629",
        "0x53B6b38A6432A2C7AC2BaCe294989067533f725d",
        "0xB1B7eB1925EF6b9e49f7Ff0B0212385f1BE935d3",
        "0xB814661cd01f5391C0B29Ada0c30B3756bF64068",
        "0x4D46897c3212741c199a79Bd616344d5845Ad3b3",
        "0xE1f65D0818756E0dE2c8314f00467a9246e72725",
        "0x5105A323C296C96fd5D49977b0E05ff02DFB503f",
        "0xa8d2a5b20c570Ac91E39F7e0460F501E837656D0",
        "0x85F9a666F0FA8BFc254BA1Ce102f09881d528a82",
        "0x2D33465B5e0e2420017c7A6cD81d0e9ea1E48446",
        "0xB212F2C4B0509f9E17e355249ADA9acc66b3684C",
        "0x242F684a74F610b86f9e2299Bb63088db4e4e4BF",
        "0xA68dA471f0Cd389FC94A05de4349Aad8303B1711",
        "0xc3C8476eBe9f8cB776bcD18FaC10eb203754A78A",
        "0xeFE1DAA340cF0c3289e263B013b104e980327222",
        "0xECA91E15D17Cf0EcDB4A74bb69031A9353742376",
        "0x8976903d9A733804d5D53fbB802a7D13aF42B7F5",
        "0xcC44a0D74af29c90565433Ab31F1098b60eC9A7F",
        "0x460e5c41Db9A75BEb349bD545000cd1277CA6b6e",
        "0x6Be4DD8786197D8d116bda25d76F219fdd5d3aaf",
        "0x9cB491caf8C3eFFe110e960615f58177cFa55d6D",
        "0xCc4c3253bEF68189E8fB69052d239B5dca3a56Cf",
        "0xd9BA11771eCf55a780E3692eFEC8D3081Ba0E81a",
        "0x21B5974589fEb681F08F894cEB4126060FCe3aDb",
        "0xeFe687ecab2BF0dF150aca6Df269d2e2ef4c416a",
        "0xD2b7f41B2AEFe05de9d863bA4dA84c1BE573100D",
        "0x1986d4315b507fd662A69CEE1e4bF75b7037af2a",
        "0x80a978596580568008c4e416622394a25d08Cfe9",
        "0x2d9aE71acf0E1D6Ff6b9418040e2Ad7825D1A1e5",
        "0x53EC55e5F5Bf86a9CF89561e0Bd9cA97AaCdaCD3",
        "0xDC660d9D014e3329C2A4bB5349578940ec59ED29",
        "0x624b6489515520F53f68ddE28DbaF10b9Ebec4C4",
        "0x7fd339D3a3712574c98d7e404c0dDc61298CF501",
        "0x310516AD94FedBFE7a315052737764CE231E5b66",
        "0x90b2cC8d5B5F4180Ce5E270771D598141B2bAdfF",
        "0xA4375687De06018c0929FF19464dA147B4F1B254",
        "0xe6863aF3640feD471b1bC02631ab61E3B7078d75",
        "0x7c2Ac0D354eBf86d52A68568D81e3F33579a3676",
        "0xeb554854817EA8a41C74f728495b7A051bFDb685",
        "0x4350d53da1bcaedB8eeB947D51762B06b7755641",
        "0xcE457dE7f54fE452754f1Abd591BDbfC0c31BB83",
        "0x37d2019131283908CA6906f4461b1AE6eBF391A7",
        "0x29dd10B8031076BC92a32103E763D30A4A6F44E3",
        "0x3415e9D4CFB4A04835c8f5BC1ce383920095Fe21",
        "0xf8b8Fb6be391B4Dd89909476918B2566b7C15Db7",
        "0x0059785D3244b7744a7Ac6Ff61D34fAa75b00Bd5",
        "0x5044D270Ea2E9a2C322219bA31f3b2F73B9C4d21",
        "0x12F64ca2d33DF49518fB9B16ffbE0dDaf83DEDc5",
        "0xb4A65BED1c2ee67bd0A3Bec25005A572B4F3E617",
        "0x37fe8Cf68c512069A88BD024F02C1fd16EF57Df1",
        "0x41Deb88dEf22F776590823F0805c741D2dC1D67B",
        "0x56F02A0297501B4E06e02C90268649d8D902e4bA",
        "0x3E61688FD8D5349Cd5CE35CaA33E74646185E8F1",
        "0x8bcf0AD594E30aB579B063Dd1e829507314d578e",
        "0xC1783caD8811aa1e71968D34bcC1896AeA878Ec4",
        "0xb11bB0DB3c6742AC8CB3f49fD700aDdbE594F7Ba",
        "0x708A855a84B57cC4ce4b75701B9a1BB57B66e5B3",
        "0x83f2304fD78A62D3dFd102892750281d5f1baAc4",
        "0xF866C793Aae7cD58E7c14711e9ABbaE426CAF551",
        "0x9DD06fc675fbF3b27aD87de3311301E35De0E068",
        "0x0987f873474ef01489D7e149F5ac86e57787927E",
        "0xCA63Fdd3D145f9671B106762836DE7d0E1ecd3F1",
        "0x7220534366868eEb138f195A396834360882cadf",
        "0x156CC017Ef46c90de4445B336f78C7285950ECD0",
        "0x164ea6327fC5d430927e9Ec158C0320132bE2Ce3"
        ];
        const { MerkleTree } = require('merkletreejs');
        const keccak256 = require('keccak256');
        let leafNodes = wt.map(address => keccak256(address));
        let tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
        console.log('Tree: ', tree.toString());
        const root = tree.getRoot();
        //console.log("root:",root);
      let leaf = keccak256("0x96Dc7c7056cA6B7aC33a04b294cd74af001134Fe");
      let proof = tree.getHexProof(leaf);
      console.log("proof:",proof);
      
      }
     
      
    render(){
      return (<div></div>)
    }
}