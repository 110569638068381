import Web3 from "web3";

const getWeb3 = () =>
  new Promise((resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    window.addEventListener("load", async () => {
      // Modern dapp browsers...
      if (typeof window !== "undefined" && typeof window.ethereum !=="undefined") {
        
        try{
          await window.ethereum.request({method:"eth_requestAccounts"});
          const web3 = new Web3(window.ethereum);
          resolve(web3);
        }catch(error){
          reject(error);
        }
      }
      
      // Fallback to localhost; use dev console port by default...
      else {
          reject("notfound");
      }
    });
  });

export default getWeb3;
