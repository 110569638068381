import React, { Component } from "react";
import axios from "axios";
import { Modal,message } from 'antd';
import connectWeb3 from "../../connectWeb3";
import { SERVER_URL } from "../../utils/constant";
import Copy from '../../assets/copy.png'
import Xiala from '../../assets/xia-icon.png'
export default class Connect extends Component {
  state = {
    web3: null, 
    networkId:null,
    accounts: null, 
    currentAddress:null,
    role:null,
    token:null,
    showModel:false,
    disConnectModal:false
  };
  componentDidMount = async()=>{
    let that = this;
    let web3 = localStorage.getItem("web3");
    let currentAddress=localStorage.getItem("currentAddress");
    let role=localStorage.getItem("role");
    let token=localStorage.getItem("token");
    if( !web3 || !currentAddress || !role || !token){
      this.clearConnect();
    }else{
      this.setState({
        web3,currentAddress,role,token
      });
      this.forceUpdate();
    }
    window.ethereum.on('accountsChanged', (accounts)=> {
      console.log("accountsChanged");
      that.clearConnect();
      that.setState({accounts:null,currentAddress:null,web3:null,networkId:null,role:null,token:null});
    });
    
  };
  componentWillUnmount = async()=>{
    if(window.ethereum.removeListener){
      window.ethereum.removeListener('accountsChanged',()=>{});
    }
  };
  changeAccount = async(accounts)=>{
    this.clearConnect();
    this.setState({accounts:null,currentAddress:null,web3:null,networkId:null,role:null,token:null});
    if(accounts.length!=0){
      this.connectWeb3();
    }
  };
  clearConnect =() =>{
    localStorage.removeItem("web3");
    localStorage.removeItem("currentAddress");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
  };
  auth = () => {
    let that=this;
    
  };
  goEtherscan=()=>{
    window.open("https://etherscan.io/address/"+this.state.currentAddress,'_blank');
  };
  copy=()=>{
    const textToCopy=this.state.currentAddress;
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // 使text area不在viewport，同时设置不可见
    textArea.style.position = "absolute";
    textArea.style.opacity = 0;
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    // 执行复制命令并移除文本框
    if(document.execCommand('copy')){
      message.info({
        content:"Copy success",
        style:{
            marginTop:"30vh"
        }
      });
    }else{
      message.info({
        content:"Copy error",
        style:{
            marginTop:"30vh"
        }
      });
    }
    textArea.remove();
     
  };
  handleOk = () => {
    window.open("https://metamask.io/",'_blank');
    this.setState({showModel:false});
  };
  handleCancel = () => {
    this.setState({showModel:false});
  };
  switchDisconnectModal = ()=>{
    
    let disConnectModal=!this.state.disConnectModal;
    this.setState({
      disConnectModal:disConnectModal
    })
  }
  disConnect = () => {
    this.clearConnect();
    this.setState({accounts:null,currentAddress:null,web3:null,networkId:null,role:null,token:null});
    this.switchDisconnectModal();
  };
  connectWeb3 = async ()=>{
    let that=this;
    try { 
      const web3 = await connectWeb3();
      const accounts = await web3.eth.getAccounts();
      const currentAddress=accounts[0];
      const networkId = await web3.eth.net.getId();
      console.log(currentAddress);
      axios.get('/api/signature/getNonce',{
        params:{
          'address':currentAddress
        }
      }).then(function (response) {
          if(  (response.status==200)  && (response.data.code==200) ){
            let nonce=response.data.nonce;
            
            web3.eth.personal.sign(nonce,currentAddress,function(result, signature){
              axios.post("/api/signature/authentication",{
                'nonce':nonce,
                'address':currentAddress,
                'signature':signature
              }).then(function(res){
                if( (res.status==200) && (res.data.code==200) ){
                    that.setState({
                      web3:web3,
                      accounts:accounts,
                      networkId:networkId,
                      currentAddress:currentAddress,
                      role:res.data.data.role,
                      token:res.data.data.token
                    });
                    localStorage.setItem("role",res.data.data.role);
                    localStorage.setItem("token",res.data.data.token);
                    localStorage.setItem("web3",web3);
                    localStorage.setItem("currentAddress",accounts[0]);
                    localStorage.setItem("networkId",networkId);
                    that.props.fnTrans();
                }
              });
            });
          }
        }).catch(function (error) {
        });
    } catch (error) {
      if(error=="notfound"){
        this.setState({showModel:true});
      }
    }
  };
  render() {
            const currentAddress = this.state.currentAddress;
            const disConnectModal=this.state.disConnectModal;
            let button;

            if (currentAddress!==null && disConnectModal) {
            let jianAddress=currentAddress.substr(0,4)+"..."+currentAddress.substr(-4)
              button = (
                <div className="connect-parent">
                  <div className="btn btn1 f-middle" >{jianAddress}<img onClick={(e)=>this.switchDisconnectModal(e)} style={{width:"25px",marginLeft:"10px"}} src={Xiala} /></div>
                  <div className="connect-modal">
                    <div className="connect-inner">
                      <div className="wallet-details">
                        <a onClick={(e)=>this.goEtherscan(e)} className="details-address" id="address">{currentAddress}</a>
                        <div className="copy"  onClick={(e)=>this.copy(e)}><img  style={{width:"25px"}} src={Copy} /></div>
                      </div>
                      <div onClick={(e)=>this.disConnect(e)} className="logout-button btn btn1 f-middle">Disconnect</div>
                    </div>
                  </div>
                </div>
              
              );
            } else if(currentAddress!==null && !disConnectModal){
              let jianAddress=currentAddress.substr(0,4)+"..."+currentAddress.substr(-4)
              button = (
                <div className="connect-parent">
                  <div className="btn btn1 f-middle" >{jianAddress}<img onClick={(e)=>this.switchDisconnectModal(e)} style={{width:"25px",marginLeft:"10px"}} src={Xiala} /></div>
                </div>
              );
            }
            else{
              button = <div className="btn btn1 f-middle" onClick={(e)=>this.connectWeb3(e)}>Connect</div>;
            }
    return (
      <div style={{float:'right'}}>
         {button}
         <Modal title="未发现metamask，前去下载吗？" visible={this.state.showModel} onOk={this.handleOk} onCancel={this.handleCancel}>
                  <p>go download metemask</p>
                  
                </Modal>
      </div>
           
    );
  }
}