import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button,message } from 'antd';
import 'antd/dist/antd.css';
import MenderClubContract from "../../contracts/MenderClub.json"
import connectWeb3 from "../../connectWeb3";
import axios from 'axios';
import MintSlider from "../../components/MintSlider";
import { SERVER_URL ,WHITE_LIST,CONTRACT_ADDRESS} from "../../utils/constant";
import logoImg from '../../assets/logo.png'
import yinhaoImg from '../../assets/yinhao.png'
export default class Contributor extends Component {
    state = { 
        web3: null, 
        networkId:null,
        accounts: null, 
        currentAddress:null,
        contract: null ,
        showModel:false,
        mintshowlist:[],
        mendernft:null,
        playUrl: null,
        isModalVisible:false,
        whitelistAddresses:[],
        breakStatus:false,
        tree:null,
        proof:[],
        mintSignVisible:false
      };
      componentDidMount = async () => {
        
        let that=this;
            
        try { 
          const web3 = await connectWeb3();
          const accounts = await web3.eth.getAccounts();
          const networkId = await web3.eth.net.getId();
          const instance = new web3.eth.Contract(
            MenderClubContract.abi,CONTRACT_ADDRESS
          );
          this.setState({ web3, accounts, networkId,currentAddress:accounts[0],contract: instance});
        } catch (error) {
          //if(error=="notfound"){
            //this.setState({showModel:true});
          //}
        }
        if(this.state.web3!==null){
          window.ethereum.on('accountsChanged', function (accounts) {
            
            if(accounts.length==0){
              that.setState({accounts:null,currentAddress:null});
            }else{
              that.setState({accounts,currentAddress:accounts[0]})
            }
          })
          window.ethereum.on('chainChanged', (chainId) => {
            that.setState({networkId:chainId});
          });
        }
         await axios.get(SERVER_URL+'/api/index')
           .then(function (response) {
            that.setState({
              mintshowlist:response.data.data.mintshowList,
            });
          })
          .catch(function (error) {
          });
      };
      
      connectWeb3 = async ()=>{
        try { 
          const web3 = await connectWeb3();
          const accounts = await web3.eth.getAccounts();
          const networkId = await web3.eth.net.getId();
          const instance = new web3.eth.Contract(
            MenderClubContract.abi,CONTRACT_ADDRESS
          );
          this.setState({ web3, accounts, networkId,currentAddress:accounts[0],contract: instance});
        } catch (error) {
          if(error=="notfound"){
            this.setState({showModel:true});
          }
        }
      };

      mintSign=async(e)=>{
        this.setState({mintSignVisible:true});
      };
      signHandleOk=async(e)=>{
        console.log("singHandleOK:",this.state.currentAddress)
        await axios.get('http://localhost:9001/sign', {
          params: {
            address: this.state.currentAddress
          }
        })
           .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
          });
      };
      signHandleCancle=async(e)=>{
        this.setState({mintSignVisible:false});
      };
      mint = async (e) => {
      
        const { web3,accounts,currentAddress, contract,proof,leixing} = this.state;  
        if(web3===null){
          message.info({
            content:'please connect to metamask',
            style:{
              position:'fixed',
            top:"50%",
            left:"50%"
            }
          });
          return false;
        }
       let res= await contract.methods.contriMint().send({ from: accounts[0] });
       //console.log(res);
           if(res.status===true){
             
            message.info({
              content:'mint success',
              style:{
                position:'fixed',
            top:"50%",
            left:"50%"
              }
            });
          }else{
            message.info({
              content:'mint error',
              style:{
                position:'fixed',
                top:"50%",
                left:"50%"
              }
            });
          }
      };
      
      handleOk = () => {
        window.open("https://metamask.io/",'_blank');
        this.setState({showModel:false});
      };
      handleCancel = () => {
        this.setState({showModel:false});
      };
      waiting=()=>{
        message.info({
          content:'Time to be determined,follow our twitter',
          style:{
            position:'fixed',
            top:"50%",
            left:"50%"
          }
        });
      };
      mintwaiting=(e)=>{
        message.info({
          content:'Time to be determined,follow our twitter',
          style:{
            position:'fixed',
            top:e.currentTarget.getBoundingClientRect().top+"px",
            left:e.currentTarget.getBoundingClientRect().left+"px"
          }
        });
      };
      waitingFinance=(e)=>{
        message.info({
          content:'upcoming',
          style:{
            position:'fixed',
            top:e.currentTarget.getBoundingClientRect().top+"px",
            left:e.currentTarget.getBoundingClientRect().left+"px"
          }
        });
      };
      scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
        }
      };
      tolink=(content)=>{
        window.open(content,'_blank');
      };
      playMenderNft=()=>{
        if(this.state.mendernft){
          this.openVideo(this.state.mendernft.url);
        }
        return false;
      };
      openVideo=(url)=>{
        this.setState({
          playUrl:"http://www.menderclub.co/prod-api"+url
        });
        this.showModal();
        
      };
      showModal = () => {
        this.setState({
          isModalVisible:true,
        });
      };
      downMetamaskModal=()=>{
        this.setState({showModel:true});
      }

      hideModel = () => {
        this.setState({
          isModalVisible:false
        });
      };
    render(){
            const currentAddress = this.state.currentAddress;
            let button;
            if (currentAddress!==null) {
            let jianAddress=currentAddress.substr(0,4)+"..."+currentAddress.substr(-4)
              button = (<div className="btn btn1 f-middle" >{jianAddress}</div>);
            } else {
              button = <div className="btn btn1 f-middle" onClick={(e)=>this.connectWeb3(e)}>Connect</div>;
            }
            return (
              <div>
                <div className="content-wrapper">
                <div className="main-header clearfix">
                    <div className="header-left clearfix">
                      <div className="left-logo-container">
                        <div className="left-logo">
                        <NavLink to="/" ><img src={logoImg} /></NavLink>
                      </div>
                      </div>
                      <div className="left-menu">
                        <div className="menu-item">
                          <span className="menu-text">MenderShow</span>
                          <div className="menu-item-cat f-miner">
                            <div className="cat-item"><NavLink  to="/video" >Video</NavLink></div>
                            <div className="cat-item"><NavLink  to="/photo" >Photo</NavLink></div>
                          </div>
                          </div>
                        
                        <div className="menu-item" ref="finance" onClick={(e)=>this.waitingFinance(e)}><span className="menu-text">Finance</span></div>
                        <div className="menu-item"> <NavLink to="/" ><span className="menu-text">FAQ</span></NavLink></div>
                        <div className="menu-item"><NavLink to="/" ><span className="menu-text">Roadmap</span></NavLink></div>
                        <div className="menu-item  wiki" onClick={this.tolink.bind(this,"https://menderclub.gitbook.io/internal-wiki/")}><span className="menu-text">中文Wiki</span></div>
                        <div className="menu-item"><NavLink to="/builder" ><span className="menu-text">Builder</span></NavLink></div>
                      
                      </div>
                    </div>
                    <div className="header-right">
                        {button}
                        <div className="header-right-link clearfix">
                          <div className="link-item link-item1" onClick={this.tolink.bind(this,"https://twitter.com/MenderClub")}></div>
                          <div className="link-item link-item2" onClick={this.tolink.bind(this,"https://discord.com/invite/wFS2xvWJBV")} ></div>
                          <div className="link-item link-item3" onClick={this.tolink.bind(this,"https://opensea.io/collection/menderclub")} ></div>
                          <div className="link-item link-item4" onClick={this.tolink.bind(this,"https://www.youtube.com/channel/UCOVcUyk2Z7Z83ZRinGBeZsw/videos")} ></div>
                        </div>
                    </div>
                  </div>

                  <div className="mint-con">
                    <div className="mint-left" >
                      <div className="mint-context">
                          <div className="mint-context-line"><span >Welcome to </span><span className="linear">MenderClub</span></div>
                          <div className="mint-context-line">Let's explore a different</div>
                          <div className="mint-context-line">NFT world together</div>
                          <div className="p10">
                            <div className="mint-context-line2">MenderClub includes NFT,Community,Finance,MenderShow</div>
                            <div className="mint-context-line2">Metaverse,etc.</div>
                          </div>
                        
                      </div>
                      <div className="clearfix mint-btn-c">
                        <div className="btn1" ref="mintbtn" onClick={(e)=>this.mintSign(e)}>mint</div>
                        
                      </div>
                    </div>
                    <div className="mint-right">
                        <div className="mint-swiper">
                          <MintSlider list={this.state.mintshowlist} />
                        </div>
                    </div> 
                  </div>
                  

                  

                  

                  <div className="main-bottom clearfix">
                    <div className="bottom-left">
                      <div className="bottom-left-line1"><img src={yinhaoImg} /></div>
                      <div className="bottom-left-line2 f-middle">2022 MenderClub.All rights reserved</div>
                      <div className="bottom-left-line3 clearfix">
                        <div>
                        {button}
                        <div className="header-right">
                          <div className="header-right-link clearfix">
                          <div className="link-item link-item1" onClick={this.tolink.bind(this,"https://twitter.com/MenderClub")}></div>
                          <div className="link-item link-item2" onClick={this.tolink.bind(this,"https://discord.com/invite/wFS2xvWJBV")} ></div>
                          <div className="link-item link-item3" onClick={this.tolink.bind(this,"https://opensea.io/collection/menderclub")} ></div>
                          <div className="link-item link-item4" onClick={this.tolink.bind(this,"https://www.youtube.com/channel/UCOVcUyk2Z7Z83ZRinGBeZsw/videos")} ></div>
                     
                          </div>
                        </div>
                        </div>
                        
                        
                        
                      </div>
                    </div>
                  </div>

                

                </div>

                <Modal title="未发现metamask，前去下载吗？" visible={this.state.showModel} onOk={this.handleOk} onCancel={this.handleCancel}>
                  <p>go download metemask</p>
                  
                </Modal>

                <Modal title="Mint Sign" visible={this.state.mintSignVisible} onOk={this.signHandleOk} onCancel={this.signHandleCancel}>
                  <p></p>
                </Modal>

                <Modal width="800px" title="Welcome to MenderClub" visible={this.state.isModalVisible} onOk={this.hideModel.bind(this)} onCancel={this.hideModel.bind(this)}>
                    <video width="100%" height="100%" controls>
                      <source  src={this.state.playUrl} type="video/mp4" />
                      您的浏览器不支持Video标签。
                    </video>
                </Modal>

              </div>
            )
            
          
    }
}