import React, { Component } from "react";
import yinhaoImg from '../../assets/yinhao.png'
import Connect from "../Connect";
export default class Bottom extends Component {
  render() {
    return (
      <div className="main-bottom clearfix">
        <div className="bottom-left">
          <div className="bottom-left-line1"><img src={yinhaoImg} /></div>
          <div className="bottom-left-line2 f-middle">2022 MenderClub.All rights reserved</div>
          <div className="bottom-left-line3 clearfix">
            <div>
            <Connect />
            <div className="header-right">
              <div className="header-right-link clearfix">
              <div className="link-item link-item1" onClick={this.tolink.bind(this,"https://twitter.com/MenderClub")}></div>
              <div className="link-item link-item2" onClick={this.tolink.bind(this,"https://discord.com/invite/wFS2xvWJBV")} ></div>
              <div className="link-item link-item3" onClick={this.tolink.bind(this,"https://opensea.io/collection/mendernft")} ></div>
              <div className="link-item link-item5" onClick={this.tolink.bind(this,"https://opensea.io/collection/menderclub")} ></div>
              <div className="link-item link-item4" onClick={this.tolink.bind(this,"https://www.youtube.com/channel/UCOVcUyk2Z7Z83ZRinGBeZsw/videos")} ></div>
        
              </div>
            </div>
            </div>
            
            
            
          </div>
        </div>
      </div>
    );
  }

  tolink=(content)=>{
    window.open(content,'_blank');
  };
}