import React, { Component } from "react";
export default class TimeLine extends Component {
  render() {

    const listItems = this.props.list.map((item,index) =>
      <li key={index}  className={ item.zhixing === 1?'active':''}>
        <h4 className="f-big">{item.title}</h4>
        <p className="f-miner">{item.content}</p>
        <div className="circle">{index+1}</div>
      </li>
    );
    return (
      <div className="timeline-con">
        <div className="about2">
        <div className="wrapper clearfix-time">
            <ul className="clearfix-time">
              {listItems}
            </ul>
          </div>
        </div>
      </div>
      
    );
  }
}