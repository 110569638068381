import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cranks1 from '../../assets/Cranks-1.png'
import Cranks2 from '../../assets/Cranks-2.png'
import mintSlider from '../../assets/mint-slider.png'
import axios from 'axios';
export default class MintSlider extends Component {
  state = {
    list:[]
  };
  componentDidMount = async()=>{
    // let that=this;
    // await axios.get('http://59.110.172.63:9002/api/Index/getTeam')
    //   .then(function (response) {
    //     that.setState({
    //       list:response.data.data.teamList
    //    })
    //   console.log(that.list);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

  }
  render() {
    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 1500,
      cssEase: "linear"
    };
    const listItems = this.props.list.map((item,index) =>
      <div key={index} className="mint-slider-item">
        <img className="img"   src={"http://www.menderclub.co/prod-api"+item.url}/>
      </div>
    );
    return (
      <div className="mint-silder" >
        <Slider {...settings}>
          {listItems}
        </Slider>
      </div>
    );
  }
}