import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Modal} from 'antd';
import axios from 'axios';
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import { SERVER_URL } from "../../utils/constant";
import tuiteImg from '../../assets/header-icon1.png'

export default class Video extends Component {
    state = { 
       
        list:[],
        showModel:false,
      };
      componentDidMount = async () => {
        
          let that=this;
         await axios.get('/api/index/getPhotoList')
           .then(function (response) {
            that.setState({
              list:response.data.data.list,
            });
          })
          .catch(function (error) {
          });
      };
      tolink=(e)=>{
        if( (e.content!=null) && (e.content!="")){
          window.open(e.content,'_target')
        }
      };
      
      
    render(){
            const list = this.state.list.map((item,index)=>
            <div className="team-silder-item" key={index}>
              <div className="team-silder-item-main">
                <div className="team-silder-img">
                  <img  src={"http://www.menderclub.co/prod-api"+item.url} />
                  </div>
                <div className="team-silder-name f-middle">{item.name}</div>
                <div className="team-silder-desc" ><img onClick={this.tolink.bind(this,item)}  src={tuiteImg} /></div>
                
              </div>
            </div>
            );
            return (
              <div>
                <div className="content-wrapper">
                  <Header />
                  <div className="video-wrapper">
                    <div className="team-silder clearfix">
                      {list}
                    </div>
                  </div>
                  <Bottom />
                </div>
              </div>
            )
    }
}