import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button,message } from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import TeamSlider from "../../components/TeamSilder";
import Question from "../../components/Question";
import TimeLine from "../../components/TimeLine";
import MenderShow from "../../components/MenderShow";
import MintSlider from "../../components/MintSlider";
import Connect from "../../components/Connect"
import { SERVER_URL ,WHITE_LIST} from "../../utils/constant";
import logoImg from '../../assets/logo.png'
import yinhaoImg from '../../assets/yinhao.png'
export default class Index extends Component {
    connectRef=React.createRef();
    state = { 
        showModel:false,
        mendershowList:[],
        teamList:[],
        fqaList:[],
        roadmapList:[],
        mintshowlist:[],
        mendernft:null,
        playUrl: null,
        isModalVisible:false,
      };
      componentDidMount = async () => {
        
        let that=this;
        
         await axios.get('/api/index')
           .then(function (response) {  
            that.setState({
              mendershowList:response.data.data.mendershowList,
              fqaList:response.data.data.fqaList,
              roadmapList:response.data.data.roadmapList,
              teamList:response.data.data.teamList,
              mintshowlist:response.data.data.mintshowList,
              mendernft:response.data.data.mendernft
            });
          })
          .catch(function (error) {
          });
      };
      waiting=()=>{
        message.info({
          content:'Time to be determined,follow our twitter',
          style:{
            position:'fixed',
            top:"50%",
            left:"50%"
          }
        });
      };
      mintwaiting=(e)=>{
        
      };
      waitingFinance=(e)=>{
        message.info({
          content:'upcoming',
          style:{
            position:'fixed',
            top:e.currentTarget.getBoundingClientRect().top+"px",
            left:e.currentTarget.getBoundingClientRect().left+"px"
          }
        });
      };
      scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
        }
      };
      tolink=(content)=>{
        window.open(content,'_blank');
      };
      playMenderNft=()=>{
        if(this.state.mendernft){
          this.openVideo(this.state.mendernft.url);
        }
        return false;
      };
      openVideo=(url)=>{
        this.setState({
          playUrl:"http://www.menderclub.co/prod-api"+url
        });
        this.showModal();
        
      };
      showModal = () => {
        this.setState({
          isModalVisible:true,
        });
      };

      hideModel = () => {
        this.setState({
          isModalVisible:false
        });
      };
    render(){
            return (
              <div>
                <div className="content-wrapper">
                <div className="main-header clearfix">
                    <div className="header-left clearfix">
                      <div className="left-logo-container">
                        <div className="left-logo">
                        <NavLink to="/" ><img src={logoImg} /></NavLink>
                      </div>
                      </div>
                      <div className="left-menu">
                        <div className="menu-item">
                          <span className="menu-text">MenderShow</span>
                          <div className="menu-item-cat f-miner">
                            <div className="cat-item"><NavLink  to="/video" >Video</NavLink></div>
                            <div className="cat-item"><NavLink  to="/photo" >Photo</NavLink></div>
                          </div>
                          </div>
                        
                        <div className="menu-item" ref="finance">
                          <span className="menu-text">Finance</span>
                          <div className="menu-item-cat f-miner">
                            <div className="cat-item"><NavLink  to="/tool" >MenderTool</NavLink></div>
                            <div className="cat-item"><NavLink  to="/news" >MenderNews</NavLink></div>
                      
                          </div>
                        </div>
                        <div className="menu-item" onClick={() => this.scrollToAnchor("Faq")}><span className="menu-text">FAQ</span></div>
                        <div className="menu-item" onClick={() => this.scrollToAnchor("Roadmap")}><span className="menu-text">Roadmap</span></div>
                        <div className="menu-item  wiki" onClick={this.tolink.bind(this,"https://menderclub.gitbook.io/internal-wiki/")}><span className="menu-text">中文Wiki</span></div>
                        <div className="menu-item"><NavLink to="/builder" ><span className="menu-text">Builder</span></NavLink></div>
                      
                      </div>
                    </div>
                    <div className="header-right">
                        <Connect ref={this.connectRef} />
                        <div className="header-right-link clearfix">
                          <div className="link-item link-item1" onClick={this.tolink.bind(this,"https://twitter.com/MenderClub")}></div>
                          <div className="link-item link-item2" onClick={this.tolink.bind(this,"https://discord.com/invite/wFS2xvWJBV")} ></div>
                          <div className="link-item link-item3" onClick={this.tolink.bind(this,"https://opensea.io/collection/mendernft")} ></div>
                          <div className="link-item link-item5" onClick={this.tolink.bind(this,"https://opensea.io/collection/menderclub")} ></div>
                          <div className="link-item link-item4" onClick={this.tolink.bind(this,"https://www.youtube.com/channel/UCOVcUyk2Z7Z83ZRinGBeZsw/videos")} ></div>
                        </div>
                    </div>
                  </div>

                  <div className="mint-con">
                    <div className="mint-left" >
                      <div className="mint-context">
                          <div className="mint-context-line"><span >Welcome to </span><span className="linear">MenderClub</span></div>
                          <div className="mint-context-line">Let's explore a different</div>
                          <div className="mint-context-line">NFT world together</div>
                          <div className="p10">
                            <div className="mint-context-line2">MenderClub includes NFT,Community,Finance,MenderShow</div>
                            <div className="mint-context-line2">Metaverse,etc.</div>
                          </div>
                        
                      </div>
                      <div className="clearfix mint-btn-c">
                        <div className="btn1" ref="mintbtn"><NavLink to="/mint" ><span>mint</span></NavLink></div>
                        <div className="btn1 btn-play" onClick={this.playMenderNft.bind(this)}>
                          <div className="mint-btn-play">
                            <div className="mint-play"></div>
                            <div className="mint-play-text" >MenderNFT</div>
                          </div>
                            
                        </div>
                      </div>
                    </div>
                    <div className="mint-right">
                        <div className="mint-swiper">
                          <MintSlider list={this.state.mintshowlist} />
                        </div>
                    </div> 
                  </div>
                  <div className="mendershow-con">
                    <MenderShow list={this.state.mendershowList} />
                  </div>
                  <div className="timeline-blank" id="Roadmap">
                    <div className="timeline-title f-biger">Roadmap</div>
                    <TimeLine list={this.state.roadmapList} />
                  </div>
                  <div className="question-con" id="Faq">
                    <Question list={this.state.fqaList} />
                  </div>

                  <div className="team-con" id="Team">
                    <div className="team-title f-biger">TEAM</div>
                    <div className="team-body">
                    <TeamSlider list={this.state.teamList} />
                    </div>
                  </div>

                  

                  <div className="main-bottom clearfix">
                    <div className="bottom-left">
                      <div className="bottom-left-line1"><img src={yinhaoImg} /></div>
                      <div className="bottom-left-line2 f-middle">2022 MenderClub.All rights reserved</div>
                      <div className="bottom-left-line3 clearfix">
                        <div>
                        <Connect />
                        <div className="header-right">
                          <div className="header-right-link clearfix">
                          <div className="link-item link-item1" onClick={this.tolink.bind(this,"https://twitter.com/MenderClub")}></div>
                          <div className="link-item link-item2" onClick={this.tolink.bind(this,"https://discord.com/invite/wFS2xvWJBV")} ></div>
                          <div className="link-item link-item3" onClick={this.tolink.bind(this,"https://opensea.io/collection/mendernft")} ></div>
                          <div className="link-item link-item5" onClick={this.tolink.bind(this,"https://opensea.io/collection/menderclub")} ></div>
                          <div className="link-item link-item4" onClick={this.tolink.bind(this,"https://www.youtube.com/channel/UCOVcUyk2Z7Z83ZRinGBeZsw/videos")} ></div>
                     
                          </div>
                        </div>
                        </div>
                        
                        
                        
                      </div>
                    </div>
                  </div>

                

                </div>

              

                <Modal width="800px" title="Welcome to MenderClub" visible={this.state.isModalVisible} onOk={this.hideModel.bind(this)} onCancel={this.hideModel.bind(this)}>
                    <video width="100%" height="100%" controls>
                      <source  src={this.state.playUrl} type="video/mp4" />
                      您的浏览器不支持Video标签。
                    </video>
                </Modal>

              </div>
            )
            
          
    }
}