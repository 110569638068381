import React, { Component } from "react";
import { Switch, Route,Redirect } from "react-router-dom";
//import routes from "./config/routes";
import Index from './pages/Index';
import Mint from './pages/Mint';
import { message } from 'antd';
import Contributor from './pages/Contributor';
import Proof from './pages/Proof';
import CoinDataAnalysis from './pages/CoinDataAnalysis';
import Incident from './pages/Incident';
import Video from './pages/Video';
import Builder from './pages/Builder';
import Photo from './pages/Photo';
import VideoDetails from "./pages/VideoDetails";
import "./App.css";
class App extends Component {
  state = { 
  };
  componentDidMount = async () => {
    document.title = 'MenderClub'
  };
  render() {
    return (
      <div className="App">
        <Switch>
          <Route  path="/index" component={Index} />
          <Route  path="/mint" component={Mint} />
          <Route  path="/contributor" component={Contributor} />
          <Route  path="/proof" component={Proof} />
          <Route  path="/video" component={Video} />
          <Route  path="/builder" component={Builder} />
          <Route  path="/photo" component={Photo} />
          <Route path="/videodetails/:id" component={VideoDetails} />
          <Route path="/tool" component={CoinDataAnalysis} />
          <Route path="/news" component={Incident} />
          <Redirect to="/index" />
        </Switch>
      </div>
    );
  }
}

export default App;
