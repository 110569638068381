import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Connect from "../Connect";
import logoImg from '../../assets/logo.png'
import { message } from 'antd';
export default class Header extends Component {
  render() {
    return (
      <div className="main-header clearfix">
      <div className="header-left clearfix">
        <div className="left-logo-container">
          <div className="left-logo">
          <NavLink to="/" ><img src={logoImg} /></NavLink>
        </div>
        </div>
        <div className="left-menu">
        <div className="menu-item"><NavLink to="/" ><span className="menu-text">Home</span></NavLink></div>
        <div className="menu-item">
          <span className="menu-text">MenderShow</span>
          <div className="menu-item-cat f-miner">
            <div className="cat-item"><NavLink  to="/video" >Video</NavLink></div>
            <div className="cat-item"><NavLink  to="/photo" >Photo</NavLink></div>
          </div>
        </div>
          
          <div className="menu-item">
            <span className="menu-text">Finance</span>
            <div className="menu-item-cat f-miner">
               <div className="cat-item"><NavLink  to="/tool" >MenderTool</NavLink></div>
               <div className="cat-item"><NavLink  to="/news" >MenderNews</NavLink></div>
            </div>
          </div>
          <div className="menu-item  wiki" onClick={this.tolink.bind(this,"https://menderclub.gitbook.io/internal-wiki/")}><span className="menu-text">中文Wiki</span></div>
          <div className="menu-item"><NavLink to="/builder" ><span className="menu-text">Builder</span></NavLink></div>
        </div>
      </div>

      <div className="header-right">
          <Connect fnTrans={this.reLoad.bind(this)} />
          <div className="header-right-link clearfix">
            <div className="link-item link-item1" onClick={this.tolink.bind(this,"https://twitter.com/MenderClub")}></div>
            <div className="link-item link-item2" onClick={this.tolink.bind(this,"https://discord.com/invite/wFS2xvWJBV")} ></div>
            <div className="link-item link-item3" onClick={this.tolink.bind(this,"https://opensea.io/collection/mendernft")} ></div>
            <div className="link-item link-item5" onClick={this.tolink.bind(this,"https://opensea.io/collection/menderclub")} ></div>
            <div className="link-item link-item4" onClick={this.tolink.bind(this,"https://www.youtube.com/channel/UCOVcUyk2Z7Z83ZRinGBeZsw/videos")} ></div>
          </div>
      </div>
    </div>
    );
  }
  tolink=(content)=>{
    window.open(content,'_blank');
  };
  reLoad=()=>{
    console.log("reload");
    window.location.reload();
  };
  waitingFinance=(e)=>{
    message.info({
      content:'upcoming',
      style:{
        position:'fixed',
        top:e.currentTarget.getBoundingClientRect().top+"px",
        left:e.currentTarget.getBoundingClientRect().left+"px"
      }
    });
  };
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
        let anchorElement = document.getElementById(anchorName);
        if(anchorElement) { anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'}); }
    }
  };
}