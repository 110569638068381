import React, {Component} from "react";
import 'antd/dist/antd.css';
import http from "../../utils/httpRequest"
import Header from "../../components/Header";
import toTopImg from '../../assets/scoll-top.png'
import { Input,DatePicker ,Button,Spin,Table,Switch,message } from "antd";
import ReactHTMLTableToExcel from 'react-html-table-to-excel' 
export default class CoinDataAnalysis extends Component {
    state = { 
      list:[],
      order:0,
      leixing:"",
      startdata:"",
      enddate:"",
      loading:false,
      english:false,
      fearValue:null,
      fearClass:null,
      newLowerCount:0,  //创新低数量
      newHigherCount:0,   //创新高数量
      aveDie:0,  //前五十市值平均跌幅
      stableCoin:[
        "busd","usdt","dai","usdc","tusd","usdd","usdn","cusdc","usdp","ustc","gusd","shib","btt","xec","safemoon","babydoge","elon","nft","vvs","wbtc","hbtc","steth",
        "frax","ceth","cdai","paxg","xaut","usdx","susd","eurs","alusd","lusd"
      ],
      columns : [
        {
          title: '序号',
          dataIndex: 'coinpriceOrder',
          key: 'coinpriceName',
        },
        {
          title: '币种',
          dataIndex: 'coinpriceName',
          key: 'coinpriceName',
        },
        {
          title: '现价(￥)',
          dataIndex: 'recentpriceCny',
          key: 'coinpriceName',
          render: (_, { recentpriceCny }) => (
            <>
              {
               recentpriceCny?recentpriceCny.toFixed(3):"--"
              }
            </>
          ),
        },
        {
          title: '阶段最高价(￥)',
          dataIndex: 'max_pricecny',
          key: 'coinpriceName',
          width:120,
          render: (_, { max_pricecny }) => (
            <>
              {
               max_pricecny?max_pricecny.toFixed(3):"--"
              }
            </>
          ),
        },
        {
          title: '最高价日期',
          dataIndex: 'max_time',
          key: 'coinpriceName',
          width:150,
          render: (_, { max_time }) => (
            <>
              {
               max_time?this.timestampToDate(max_time):"--"
              }
            </>
          ),
        },
        {
          title: '阶段最低价(￥)',
          dataIndex: 'min_pricecny',
          key: 'coinpriceName',
          width:120,
          render: (_, { min_pricecny }) => (
            <>
              {
               min_pricecny?min_pricecny.toFixed(3):"--"
              }
            </>
          ),
        },
        {
          title: '最低价日期',
          dataIndex: 'min_time',
          key: 'coinpriceName',
          width:150,
          render: (_, { min_time }) => (
            <>
              {
               min_time?this.timestampToDate(min_time):"--"
              }
            </>
          ),
        },
        {
          title: '跌幅',
          dataIndex: 'die',
          key: 'coinpriceName',
          sorter: (a, b) => a.die - b.die,
          render: (_, { die }) => (
            <>
              {
               (die!="NaN")?this.renderDieSpan(die):"--"
              }
            </>
          ),
        },
        {
          title: '涨幅',
          dataIndex: 'zhang',
          key: 'coinpriceName',
          sorter: (a, b) => a.zhang - b.zhang,
          render: (_, { zhang }) => (
            <>
              {
               (zhang!="NaN")?this.renderZhangSpan(zhang):"--"
              }
            </>
          ),
        },
        {
          title: '最高到最低跌幅',
          dataIndex: 'fudu',
          key: 'coinpriceName',
          width: 165,
          sorter: (a, b) => a.fudu - b.fudu,
          render: (_, { fudu }) => (
            <>
              {
               (fudu!="NaN")?this.renderDieSpan(fudu):""
              }
            </>
          ),
        },
        {
          title: '备注',
          dataIndex: 'beizhu',
          key: 'coinpriceName',
        },
      ],
      columns2 : [
        {
          title: 'Rank',
          dataIndex: 'coinpriceOrder',
          key: 'coinpriceName',
        },
        {
          title: 'Currency',
          dataIndex: 'coinpriceName',
          key: 'coinpriceName',
        },
        {
          title: 'Current price($)',
          dataIndex: 'recentpriceUsd',
          key: 'coinpriceName',
          width: 130,
          render: (_, { recentpriceUsd }) => (
            <>
              {
               recentpriceUsd?recentpriceUsd.toFixed(3):"--"
              }
            </>
          ),
        },
        {
          title: 'Stage maximum price($)',
          dataIndex: 'max_priceusd',
          key: 'coinpriceName',
          width: 185,
          render: (_, { max_priceusd }) => (
            <>
              {
               max_priceusd?max_priceusd.toFixed(3):"--"
              }
            </>
          ),
        },
        {
          title: 'Stage highest price date',
          dataIndex: 'max_time',
          key: 'coinpriceName',
          width: 175,
          render: (_, { max_time }) => (
            <>
              {
               max_time?this.timestampToDate(max_time):"--"
              }
            </>
          ),
        },
        {
          title: 'Stage lowest price（$）',
          dataIndex: 'min_priceusd',
          key: 'coinpriceName',
          width: 180,
          render: (_, { min_priceusd }) => (
            <>
              {
               min_priceusd?min_priceusd.toFixed(2):"--"
              }
            </>
          ),
        },
        {
          title: 'Stage lowest date',
          dataIndex: 'min_time',
          key: 'coinpriceName',
          width: 135,
          render: (_, { min_time }) => (
            <>
              {
               min_time?this.timestampToDate(min_time):"--"
              }
            </>
          ),
        },
        {
          title: 'Decrease',
          dataIndex: 'die',
          key: 'coinpriceName',
          sorter: (a, b) => a.die - b.die,
          render: (_, { die }) => (
            <>
              {
               (die!="NaN")?this.renderDieSpan(die):"--"
              }
            </>
          ),
        },
        {
          title: 'Increase',
          dataIndex: 'zhang',
          key: 'coinpriceName',
          sorter: (a, b) => a.zhang - b.zhang,
          render: (_, { zhang }) => (
            <>
              {
               (zhang!="NaN")?this.renderZhangSpan(zhang):"--"
              }
            </>
          ),
        },
        {
          title: 'Maximum to minimum decline',
          dataIndex: 'fudu',
          key: 'coinpriceName',
          width: 295,
          sorter: (a, b) => a.fudu - b.fudu,
          render: (_, { fudu }) => (
            <>
              {
               (fudu!="NaN")?this.renderDieSpan(fudu):""
              }
            </>
          ),
        },
        {
          title: 'Remarks',
          dataIndex: 'beizhu',
          key: 'coinpriceName',
        },
      ]
    };
    componentDidMount = async () => {
      let that=this;
      let token=localStorage.getItem("token");
      this.updateMintData();
    };
    exportExcel = () => {
      const cloneDivNode = document.getElementById('coinTable').cloneNode(true);
      const table = document.createElement('table')
      table.appendChild(cloneDivNode);
      table.setAttribute('id', 'table-to-xls');
      document.getElementById('root').appendChild(table);
      document.getElementById('test-table-xls-button').click();
      setTimeout(() => {
        document.getElementById('root').removeChild(table);
      },500)
  };
  /*恐慌算法【选择日期，自动计算创新低的币种数量，
  //比如我选择2021年11月9日-2022年1月24日，我可以发现1月24日有235个币创新低，计算日期按照我截止的1月24日号当天算，
  // 如果我再切换到6月18日，那就是75个币创新低】
  //NewLow：235    低于选择的日期内前面的最低价格，通过最低价创下的日期计算数量
  //NewHigh：10     高于选择的日期内前面的最高价格，通过最高价创下的日期计算数量
*/
scollToTop= () =>{
  let anchorElement = document.getElementById("tool");
  anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
}
  calFear= (minTime,maxTime) =>{
    let endDate;
    if(  (this.state.enddate=="") ||  (this.state.enddate==null)){
        endDate=this.getCurrentDay();
    }else{
      endDate=this.dateFormatDay(this.state.enddate,2);
    }
    if(minTime!=null){
      let minDate=this.dateFormatDay(minTime);
      if(minDate==endDate){
        this.setState((state,props)=>{
          return {
            newLowerCount:state.newLowerCount+1
          }
        })
      }
    }
    if(maxTime!=null){
      let maxDate=this.dateFormatDay(maxTime);
      if(maxDate==endDate){
        console.log("maxDate==endDate:",true);
        this.setState((state,props)=>{
          return {
            newHigherCount:state.newHigherCount+1
          }
        })
      }
    }
  };

  getCurrentDay =()=>{
    var date=new Date();
    let YY = date.getFullYear();
    let MM = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return YY + "-" + MM + "-" + DD;
  };
  dateFormatDay =(datetime,mode=1)=>{
    var date;
    switch(mode){
      case 1:
        //时间戳格式
        date=new Date(datetime*1000);
        break;
      case 2:
        //日期格式
        date=new Date(datetime);
        break;
    }
    
    let YY = date.getFullYear();
    let MM = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return YY + "-" + MM + "-" + DD;
  };
    updateMintData=async ()=>{
      let _this=this;
      this.setState({
        loading:true,
        newLowerCount:0,
        newHigherCount:0,
      });
      http("get",'/api/user/getPeriodBottomData',{
          "leixing":this.state.leixing,
          "startdate":this.state.startdata,
          "enddate":this.state.enddate
      }).then(res => {
        if(res.code===200){
          let recentPriceList=res.recentPriceList;
          let maxPriceList=res.maxPriceList;
          let minPriceList=res.minPriceList;
          let list=[];
          let beizhuList = res.beizhuList;
          if( (this.state.leixing!="") && (this.state.leixing!=null)  ){
            for (let i = 0; i < recentPriceList.length; ++i) {
              let upperName=this.state.leixing.toUpperCase();
              if(recentPriceList[i]["coinpriceName"] == upperName){
                list[recentPriceList[i]["coinpriceName"]]=recentPriceList[i];
              }
            }
            for (let i = 0; i < beizhuList.length; ++i) {
              let upperName=this.state.leixing.toUpperCase();
              if(beizhuList[i]["beizhuBody"] == upperName){
                list[beizhuList[i]["beizhuBody"]]['beizhu']=beizhuList[i]['beizhuContent'];
              }
            }
          }else{
            for (let i = 0; i < recentPriceList.length; ++i) {
              let lowerName=recentPriceList[i]["coinpriceName"].toLowerCase();
              
              let index=this.state.stableCoin.indexOf(lowerName);
              
              if(index==-1){
                
                list[recentPriceList[i]["coinpriceName"]]=recentPriceList[i];
                for (let j = 0; j < beizhuList.length; ++j) {

                  if(beizhuList[j]["beizhuBody"] == recentPriceList[i]["coinpriceName"]){
                    list[recentPriceList[i]["coinpriceName"]]['beizhu']=beizhuList[j]['beizhuContent'];
                  }
                }
              }
            }
          }
          
          
          
          
          for (let i = 0; i < maxPriceList.length; ++i) {
            if(list[maxPriceList[i]["coinpriceName"]]){
              list[maxPriceList[i]["coinpriceName"]].maxTime=maxPriceList[i].maxTime;
              list[maxPriceList[i]["coinpriceName"]].maxpriceUsd=maxPriceList[i].maxpriceUsd;
              list[maxPriceList[i]["coinpriceName"]].maxpriceCny=maxPriceList[i].maxpriceCny;
            }
          }
          for (let i = 0; i < minPriceList.length; ++i) {
            if(list[minPriceList[i]["coinpriceName"]]){
              list[minPriceList[i]["coinpriceName"]].minTime=minPriceList[i].minTime;
              list[minPriceList[i]["coinpriceName"]].minpriceUsd=minPriceList[i].minpriceUsd;
              list[minPriceList[i]["coinpriceName"]].minpriceCny=minPriceList[i].minpriceCny;
            }
          }
          let callist;
          let listKeys= Object.keys(list);
          let aveDie=0;
          let aveDieTotal=0;
          let aveDieCount=0;
            if(  (_this.state.enddate=="") ||  (_this.state.enddate==null)){
              
          
              callist=listKeys.map((keyitem,index)=>{
                let item=list[keyitem];
                let max_pricecny_temp=parseFloat(item['maxpriceCny']);
                let recent_pricecny_temp = parseFloat(item['recentpriceCny']);
                //-----------
                let max_priceusd_temp=parseFloat(item['maxpriceUsd']);
                let recent_priceusd_temp = parseFloat(item['recentpriceUsd']);
                //-----------
                let max_pricecny=(max_pricecny_temp>recent_pricecny_temp)?max_pricecny_temp:recent_pricecny_temp;
                let max_priceusd=(max_priceusd_temp>recent_priceusd_temp)?max_priceusd_temp:recent_priceusd_temp;
                //-----------
                let max_time=(max_pricecny_temp>recent_pricecny_temp)?item['maxTime']:item['recentTime'];
                //-----------
                let min_pricecny;
                let min_priceusd;
                let min_time;
                if(item['minpriceCny']){
                    let min_pricecny_temp=parseFloat(item['minpriceCny']);
                    let min_priceusd_temp=parseFloat(item['minpriceUsd']);
                    //------------------
                    min_pricecny=(min_pricecny_temp<recent_pricecny_temp)?min_pricecny_temp:recent_pricecny_temp;
                    min_priceusd=(min_priceusd_temp<recent_priceusd_temp)?min_priceusd_temp:recent_priceusd_temp;
                  }else{
                    min_pricecny=recent_pricecny_temp;
                    min_priceusd=recent_pricecny_temp;
                  }
                if( ( parseFloat(item['minpriceCny']) < recent_pricecny_temp) && (item['minpriceCny'])  ){
                  min_time=item['minTime'];
                  }else{
                  min_time=item['recentTime'];
                  }
                //输出涨跌幅
                let percent1=(  ((max_pricecny-recent_pricecny_temp)/max_pricecny)*100).toFixed(2);
                let percent2 =(( (recent_pricecny_temp-min_pricecny)/min_pricecny)*100).toFixed(2);
                let percent3 =(( (max_pricecny-min_pricecny)/max_pricecny)*100).toFixed(2);
                item['max_pricecny']=max_pricecny;
                item['max_priceusd']=max_priceusd;
                item['max_time']=max_time;
                item['min_pricecny']=min_pricecny;
                item['min_priceusd']=min_priceusd;
                item['min_time']=min_time;
                item['die']=percent1;
                item['zhang']=percent2;
                item['fudu']=percent3;
                //计算平均前五十跌幅
                if(aveDieCount<50){
                  aveDieTotal=parseFloat(aveDieTotal)+parseFloat(item['die']);
                  aveDieCount=aveDieCount+1;
                }
                
                _this.calFear(item['min_time'],item['max_time']);  //计算恐慌
                return item;
              });
            }else{
              callist=listKeys.map((keyitem,index)=>{
                
                let item=list[keyitem];
                let max_pricecny_temp=parseFloat(item['maxpriceCny']);
                let max_priceusd_temp=parseFloat(item['maxpriceUsd']);
                let min_pricecny_temp=parseFloat(item['minpriceCny']);
                let min_priceusd_temp=parseFloat(item['minpriceUsd']);
                let recent_pricecny_temp = parseFloat(item['recentpriceCny']);
                let recent_priceusd_temp = parseFloat(item['recentpriceUsd']);
                let percent1= (((max_pricecny_temp-recent_pricecny_temp)/max_pricecny_temp)*100).toFixed(2);
                let percent2 =(( (recent_pricecny_temp-min_pricecny_temp)/min_pricecny_temp)*100).toFixed(2);
                let percent3 =(( (max_pricecny_temp-min_pricecny_temp)/max_pricecny_temp)*100).toFixed(2);
                item['max_pricecny']=max_pricecny_temp;
                item['max_priceusd']=max_priceusd_temp;
                item['max_time']=item["maxTime"];
                item['min_pricecny']=min_pricecny_temp;
                item['min_priceusd']=min_priceusd_temp;
                item['min_time']=item["minTime"];
                item['die']=percent1;
                item['zhang']=percent2;
                item['fudu']=percent3;
                if(aveDieCount<50){
                  aveDieTotal=parseFloat(aveDieTotal)+parseFloat(item['die']);
                  aveDieCount=aveDieCount+1;
                }
                _this.calFear(item['min_time'],item['max_time']);  //计算恐慌
                return item;
              });
             
            }
            callist=callist.sort((a,b)=>{
              return a.coinpriceOrder-b.coinpriceOrder;
            })

            let fearJson=res.fear;
            aveDie=(aveDieTotal/aveDieCount).toFixed(2);
            _this.setState({
              list:callist,
              fearValue:fearJson?fearJson.value:"--",
              fearClass:fearJson?fearJson.value_classification:"--",
              aveDie:aveDie
            })
        }

      })
      .catch(err=>{

      }).finally(()=>{
        this.setState({loading:false});
      });
    };
    sortHandle=(order)=>{
      let sortlist=[];
      switch(order){
        case 1:    //跌幅升序
        sortlist=this.state.list.sort((a,b)=>{
          return a.die-b.die;
        })
        this.setState({list:sortlist});
        this.forceUpdate();
          break;
        case 2:   //跌幅降序
        sortlist=this.state.list.sort((a,b)=>{
          return b.die-a.die;
        })
        this.setState({list:sortlist});
        this.forceUpdate();
          break;
        case 3:    //涨幅升序
        sortlist=this.state.list.sort((a,b)=>{
          return a.zhang-b.zhang;
        })
        this.setState({list:sortlist});
        this.forceUpdate();
          break;
        case 4:    //涨幅降序
        sortlist=this.state.list.sort((a,b)=>{
          return b.zhang-a.zhang;
        })
        this.setState({list:sortlist});
        this.forceUpdate();
          break;
      }
      
    };
    changSort=async (value)=>{
      
      this.setState({
        order:value
      });
     
      this.forceUpdate();
      this.sortHandle(value);
    };
    timestampToDate=(timestamp)=>{
      let date = new Date(timestamp*1000);
      let YY = date.getFullYear();
      let MM = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + "-" + MM + "-" + DD + " " + hh + ":" + mm;
    };
    toWan = (price)=>{
      if(price>=10000){
        return price/10000+"万";
      }else{
        return price;
      }
    };
    changeL(event){
      this.setState({
        leixing:  event.target.value              
      })
    };
    changeStartDate=(m,time)=>{
      this.setState({
        startdata:time
      });
    };
    changeEndDate=(m,time)=>{
      this.setState({
        enddate:time
      });
    }
    renderDieSpan=(value)=>{
      if(value>86){
        return <span style={{color:"red",backgroundColor:"yellow"}}>{value+"%"}</span>
      }else if((value>72) && (value<86)){
        return <span style={{color:"red"}}>{value+"%"}</span>
      }else{
        return <span>{value+"%"}</span>
      }
    }

    renderZhangSpan=(value)=>{
      if(value<200){
        return <span style={{color:"red",backgroundColor:"yellow"}}>{value+"%"}</span>
      }else{
        return <span>{value+"%"}</span>
      }
    }
    changeLanguage=(checked)=>{
      this.setState({english:checked})
    }
      
    render(){
      // const list=this.state.list.map((item,index)=>
      //   <tr key={index}>
      //     <td>{item.rank}</td>
      //     <td>{item.coinprice_name}</td>
      //     <td>{this.toWan(item.recent_price)}</td>
          
      //     <td>{this.toWan(item.max_price)}</td>
      //     <td>{this.timestampToDate(item.max_time)}</td>
      //     <td>{this.toWan(item.min_price)}</td>
      //     <td className="fudu">{this.timestampToDate(item.min_time)}</td>
      //     <td className="fudu">{this.renderDieSpan(item.die)}</td>
      //     <td>{this.renderZhangSpan(item.zhang)}</td>
      //     <td>{item.beizhu}</td>
      //   </tr>
      // );
      return (<div className="coin-analysis" id="tool">
        
        <div className="content-wrapper">
           { <Header /> }
        </div>
        <div className="container-warp">
            
            <div className="form">
              <Input style={{width:"150px",marginRight:"10px"}} placeholder={this.state.english?'Currency':'币种'} type='text' value={this.state.leixing} onChange={this.changeL.bind(this)} />
              <DatePicker placeholder={this.state.english?'Start Time':'开始时间'}  onChange={this.changeStartDate.bind(this)}  />
              -
              <DatePicker placeholder={this.state.english?'End Time':'结束时间'} onChange={this.changeEndDate.bind(this)}  />
              <Button style={{marginRight:"10px"}} onClick={this.updateMintData.bind(this)} type="primary">{this.state.english?'Filter':'筛选'}</Button>
              <Button style={{marginRight:"10px"}} onClick={this.exportExcel.bind(this)} type="primary">{this.state.english?'Export':'导出'}</Button>

              <Switch onChange={this.changeLanguage} checkedChildren="EN" unCheckedChildren="ZH"  />

              <div className="fear-con">今日恐慌指数：<span className="fear-item">{this.state.fearValue?this.state.fearValue:"--"}</span ><span className="fear-item">{this.state.fearClass?this.state.fearClass:"--"}</span> </div>
              <div className="fear-con">
                NewLow：<span className="fear-item">{this.state.newLowerCount}</span >
              </div>
              <div className="fear-con">
                NewHigh：<span className="fear-item">{this.state.newHigherCount}</span >
              </div>
              <div className="fear-con">
                Index：<span className="fear-item">{this.state.aveDie}%</span >
              </div>
          </div>
          {/* <table border="1" style={{width:'100%'}} className="coin-data-table">
            <thead>
              <tr>
              <th>序号</th>
              <th>币种</th>
              <th>现价(¥)</th>
              
              <th>阶段最高价(¥)</th>
              <th>最高价日期</th>
              <th>阶段最低价(¥)</th>
              <th>最低价日期</th>
              <th className="fudu">跌幅<span  onClick={this.changSort.bind(this, (this.state.order==1)?2:1)} style={{fontSize:'26px',fontWeight: 'bolder',cursor:'pointer'}}>{this.state.order==1?"↓":"↑"}</span></th>
              <th className="fudu">涨幅<span  onClick={this.changSort.bind(this, (this.state.order==3)?4:3)} style={{fontSize:'26px',fontWeight: 'bolder',cursor:'pointer'}}>{this.state.order==3?"↓":"↑"}</span>	</th>
              <th>备注</th>
            </tr>
            </thead>
            
            <tbody>
              {list}
            </tbody>
            
          </table> */}

          <Table id="coinTable" columns={this.state.english?this.state.columns2:this.state.columns} dataSource={this.state.list} pagination={false} />
          <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="MenderFinance"
                sheet="MenderFinance"
                buttonText="Download as XLS" />
        </div>
        {this.state.loading===true?
          <div  className="loading-modal">
            <Spin className="loading-icon" size="large" />
        </div>:""
        }
        <img className="scoll-top" onClick={this.scollToTop.bind(this)}  src={toTopImg} />
        
      </div>)
    }
}