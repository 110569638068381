import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cranks1 from '../../assets/Cranks-1.png'
import Cranks2 from '../../assets/Cranks-2.png'
import axios from 'axios';
export default class TeamSlider extends Component {
  state = {
    list:[]
  };
  componentDidMount = async()=>{
    // let that=this;
    // await axios.get('http://59.110.172.63:9002/api/Index/getTeam')
    //   .then(function (response) {
    //     that.setState({
    //       list:response.data.data.teamList
    //    })
    //   console.log(that.list);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

  }
  render() {
    console.log(this.props);
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      centerPadding:'150px',
      speed: 1000,
      autoplaySpeed: 5000,
      cssEase: "linear"
    };
    const listItems = this.props.list.map((item,index) =>
      <div key={index} className="team-silder-item">
        <div className="team-silder-img"><img src={"http://www.menderclub.co/prod-api"+item.url} /></div>
        <div className="team-silder-name f-middle">{item.name}</div>
      </div>
    );

    return (
      <div className="team-silder" >
        <Slider {...settings}>
          {listItems}
          
        </Slider>
      </div>
    );
  }
}