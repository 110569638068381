import React, {Component} from "react";
import 'antd/dist/antd.css';
import { Modal} from 'antd';
import axios from 'axios';
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import { SERVER_URL } from "../../utils/constant";
import tuiteImg from '../../assets/header-icon1.png'

export default class Video extends Component {
    state = { 
       
        list:[],
        showModel:false,
        playUrl: null,
        isModalVisible:false,
      };
      componentDidMount = async () => {
       

          let that=this;
         await axios.get('/api/index/getVideoList')
           .then(function (response) {
            that.setState({
              list:response.data.data.list,
            });
          })
          .catch(function (error) {
          });
      };
      tolink=(e)=>{
        if( (e.content!=null) && (e.content!="")){
          window.open(e.content,'_target')
        }
      };

    
      
      
    render(){
            const list = this.state.list.map((item,index)=>
            <div className="team-silder-item" key={index}>
              <div className="team-silder-item-main">
                <div className="team-silder-img">
                  
                <video width="100%" height="100%" controls preload="none" poster={"http://www.menderclub.co/prod-api"+item.fengmian}>
                      <source  src={"http://www.menderclub.co/prod-api"+item.url} type="video/mp4" />
                      您的浏览器不支持Video标签。
                    </video>
                  {/* <div className="video-play juzhong"></div> */}
                  
                  </div>
                <div className="team-silder-name f-middle">{item.name}</div>
                <div className="team-silder-desc" ><img onClick={this.tolink.bind(this,item)}  src={tuiteImg} /></div>
                
              </div>
            </div>
            );
            return (
              <div>
                <div className="content-wrapper">
                  <Header />
                  <div className="video-wrapper">
                    <div className="team-silder clearfix">
                      {list}
                    </div>
                  </div>
                  
                  <Bottom />
                </div>
              </div>
            )
    }
}