import Web3 from "web3";

const connectWeb3 = async () =>
  new Promise( async (resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
      // Modern dapp browsers...
      if (typeof window !== "undefined" && typeof window.ethereum !=="undefined") {
        try{
          const web3 = new Web3(window.ethereum);
          await  window.ethereum.request({method:"eth_requestAccounts"});
          resolve(web3);
        }catch(error){
          reject(error);
        }
      }
      // Fallback to localhost; use dev console port by default...
      else {
        reject("notfound");
      }
    
  });

export default connectWeb3;
