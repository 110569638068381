import React, { Component } from "react";
import IQuestion from "../../assets/question-icon.png"
import XiaIcon from "../../assets/right-icon.png"
export default class Question extends Component {
  state = {
    index:0
  };
  handleClick= async (ind)=>{
      if(ind==this.state.index){
        this.setState({
          index:-1
        });
      }else{
        this.setState({
          index:ind
        });
      }
      
  };
  render() {
    const listItems = this.props.list.map((item,index) =>{
        return (<div onClick={this.handleClick.bind(this,index)}  className={ this.state.index === index?'question-item active':'question-item'} key={index}>
        <div className="question-item-line1">
          <div className="question-item-wen f-big weight8">{item.title}</div>
          <div className="question-item-icon">
            <div className="imgc"></div>
            </div>
        </div>
        <div className="question-item-line2 f-miner">{item.content}</div>
      </div>)
    }
        
    );
    return (
      <div>
        <div className="question-title f-biger">FAQ</div>
        <div className="question-body">
          <div className="q-flex-item q-img"><img  src={IQuestion} /></div>
          <div className="q-flex-item question-right">
            <div className="question-list">
              
            {listItems}

              
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}